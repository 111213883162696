import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,1);
    font-family: 'Barlow', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 133.7%;
    font-weight: 400;
    color: #333333;
  }

  body.fontLoaded {
    font-family: Barlow, 'Source Sans Pro', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
  }
  .public-DraftStyleDefault-block  {
    margin: 0px !important;
  }
  a {
    text-decoration: none;
  }
  p {
    font-family: 'Barlow', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 133.7%;
    [theme.breakpoints.down('sm')]: {
    font-size: 14px;
    }
  }
  h2,h1,h3,h4,h5 {
    font-family: 'Barlow', Helvetica, Arial, sans-serif;
  }


  h1{
    font-size: 48px;
    line-height: 134.6%;
    font-weight: 700;
    // [theme.breakpoints.down('sm')]: {
    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 134.6%;
    }
  }
  h2{
    font-size: 42px;
    line-height: 135.6%;
    font-weight: 700;
    @media (max-width: 600px) {
      font-size: 24px;
    }
  }
  h3{
    font-size: 25px;
    line-height: 136.7%;
    font-weight: 700;
    [theme.breakpoints.down('sm')]: {
      font-size: 20px;
    }
  }
  h4{
    font-size: 20px;
    font-weight: 500;
    line-height: 137.9%;
    margin-bottom:0.8em;
  }
  h5{
    font-size: 16px;
    font-weight: 500;
    line-height: 138.7%;
    margin-bottom:0.8em;
  }

  .carousel .slide {

    background: transparent !important;
  }  
  label {
    font-family: Barlow Semi Condensed, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }
  input {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
  }
  input#search {
    padding: 0px !important;
  }
  .cardcontent p {
    padding-top: 0;
    padding-bottom: 0;
    margin: 6px 0px;
    font-size:15px;
  }
  .fyrkcardcontent {
    padding-top: 0;
  }
  .fyrkcardcontent p {
    padding-top: 0;
    padding-bottom: 0;
    margin: 6px 0px;
    font-size:0.9rem;
  }
  .cardcontent .title {
    min-height: 58px;
  }
  .cardtext {
    min-height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tiny {
    font-size: 16px !important;
    margin-right: 4px;
  }

  .SingleSearchResultEventTimesClock:after {
    content: '\\A';
    white-space: pre;
  }
  .MuiCardHeader-title	 {
    font-weight:600;
    font-size:1.1rem;
  }
`;
export default GlobalStyle;
