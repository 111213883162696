/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import List from '../../components/List';
import LoadingIndicator from '../../components/LoadingIndicator';
import _ from 'lodash';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import {
  makeSelectCurrentUser,
  makeSelectNyheter,
  makeSelectLoading,
  makeSelectError,
  makeSelectValue,
  getLocationName,
  makeSelectCategoriesMainSelectedNames,
  makeSelectUserId,
  getFavoriter,
} from '../../containers/App/selectors';
import NyheterList from '../../components/NyheterList';
import BreadCrumb from '../../components/BreadCrumb';
import withStyles from '@material-ui/styles/withStyles';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import {
  makeSelectListView,
  makeSelectMasonryView,
  getPage,
  getMore,
} from './selectors';
import CenteredSection from './CenteredSection';
import Section from './Section';
import { loadNyheter, removeCategory, handleChangePlats } from '../App/actions';
import { toListView, toMasonryView, changePage } from './actions';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import reducer from './reducer';
import saga from './saga';
const styles = theme => ({
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',
  },
  alignleft: {
    marginLeftt: '1em',
    textAlign: 'left',
    float: 'left',
  },
  nomarginSection: {
    margin: "0 !important",
    paddingBottom: '2em',
  },
  morebutton: {
    display: 'inline-block',
    backgroundColor: '#000 !important',
    background: '#000',
    color: '#fff',
    marginLeft: '1em',
    borderRadius: 0,
    marginTop: '16px',
    padding: '4px 8px',
  },

});
export class Nyheter extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load repos
   */
  componentDidMount() {
    this.props.loadNyheter();
  }

  render() {
    const {
      loading,
      error,
      nyheter,
      classes,
      userid,
      page,
      user,
      listview,
      masonryview,
      favoriter,
      more,
      breadcrumb,
      locationname,
      location
    } = this.props;
    const reposListProps = {
      listview,
      masonryview,
      loading,
      error,
      nyheter,
      userid,
      user,
      favoriter,
    };
    let loader = '';
    if (loading) {
      loader = <List component={LoadingIndicator} />;
    }
    const breadcrumbProps = { breadcrumb, locationname ,location};

    const nextpage = page + 1;
    let morebutton = '';
    if (more) {
      morebutton = (
        <Button
          className={classes.morebutton}
          onClick={evt => {
            this.props.onMore(nextpage);
          }}
        >
          {' '}
          MER{' '}
        </Button>
      );
    }

    return (
      <article>
        <Helmet>
          <title>Nyheter</title>
          <meta name="Nyheter" content="" />
        </Helmet>

        <Section className={classes.nomarginSection}>
          <div className={classes.alignleft}>
          <BreadCrumb {...breadcrumbProps} 
              removeCategory={(id) => {
                this.props.removeCategory(id);
              }} 
              removeAllCategories={() => {
                this.props.removeCategoryAll();
              }}/>
          </div>
          <div className={classes.alignright}>
            <IconButton
              onClick={() => {
                this.props.onChangeViewMasonry();
              }}
            >
              <ViewModule />
            </IconButton>
            <IconButton
              onClick={() => {
                this.props.onChangeViewList();
              }}
            >
              {' '}
              <ViewList />{' '}
            </IconButton>
          </div>
        </Section>
        <Section className={classes.nomarginSection}>
          <NyheterList {...reposListProps} />
        </Section>

        {loader}
        <CenteredSection>{morebutton}</CenteredSection>
      </article>
    );
  }
}

Nyheter.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  nyheter: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  loadNyheter: PropTypes.func,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    removeCategory: id => {
      dispatch(removeCategory(id));
      dispatch(loadNyheter(true));
    },
    removeCategoryAll: () => {
      dispatch(removeCategory('all'));
      dispatch(loadNyheter(true));
    },
    loadNyheter: () => {
      dispatch(loadNyheter());
    },
    onChangeViewList: evt => dispatch(toListView()),
    onChangeViewMasonry: evt => dispatch(toMasonryView()),
    onMore: page => {
      dispatch(changePage(page));
      dispatch(loadNyheter(true));
    },
    removeLocation: id => {
      dispatch(handleChangePlats());
      dispatch(loadNyheter(true));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  nyheter: makeSelectNyheter(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  value: makeSelectValue(),
  breadcrumb: makeSelectCategoriesMainSelectedNames(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  listview: makeSelectListView(),
  masonryview: makeSelectMasonryView(),
  page: getPage(),
  favoriter: getFavoriter(),
  more: getMore(),
  locationname: getLocationName(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'nyheter', reducer });
const withSaga = injectSaga({ key: 'nyheter', saga });

const NyheterWithMatomo = (props) => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({});
  }, []);

  return <Nyheter {...props} />;
};

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles),
)(NyheterWithMatomo);
