/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import withWidth from '@material-ui/core/withWidth';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import KulturList from '../../components/KulturList';
import NyheterList from '../../components/NyheterList';
import EvenemangList from '../../components/EvenemangList';
import Grid from '@material-ui/core/Grid';
import { push } from 'connected-react-router';
import Favorite from '@mui/icons-material/Favorite';
import Hidden from '@material-ui/core/Hidden';

import {
  handleSearchFieldValueEvenemang,
  handleSearchEvenemang,
  loadEvenemang,
} from '../../containers/App/actions';
import RandomImageHome from '../../components/RandomImageHome';
import { useMatomo } from "@datapunt/matomo-tracker-react";

import {
  makeSelectCurrentUser,
  makeSelectUserId,
  getFavoriter,
  getHelp,
  makeSelectSearchFieldValueEvenemang,
} from '../../containers/App/selectors';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import saga from './saga';
import reducer from './reducer';
import {
  getHomeEvents,
  getHomeProfiles,
  getHomeNews,
  makeSelectListViewEvents,
  makeSelectMasonryViewEvents,
  makeSelectListViewNews,
  makeSelectMasonryViewNews,
  makeSelectListViewProfiles,
  makeSelectMasonryViewProfiles,
} from './selectors';
import {
  loadData,
  toListViewEvents,
  toMasonryViewEvents,
  toListViewNews,
  toMasonryViewNews,
  toListViewProfiles,
  toMasonryViewProfiles,
} from './actions';
import Section from './Section';
import Img1 from '../../images/hero/pexels-aleksei-pribolovski.jpg';
import Img2 from '../../images/hero/pexels-antti-kulmanen.jpg';
import Img3 from '../../images/hero/pexels-efrem-efre.jpg';
import Img4 from '../../images/hero/pexels-jibarofoto.jpg';
import Img5 from '../../images/hero/pexels-raulling.jpg';
import Img6 from '../../images/hero/pexels-thibault-trillet.jpg';
import KulturAktor from '../../images/kulturaktor.svg';
import Private from '../../images/private.svg';
import News from '../../images/news.svg';
import Magazines from '../../images/magazines.svg';
import SearchBG from '../../images/hero/hero_bg.svg';

const styles = theme => ({
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',
  },
  alignleft: {
    marginLeftt: '1em',
    textAlign: 'left',
    float: 'left',
    padding: '1em',
  },
  aligncenter: {
    textAlign: 'center',
  },
  textField: {
    width: '100%',
    border: '1px solid #d6d1f0',
    background: '#fff',
    '&:before': {
      border: 'none',
    },
    paddingLeft: '5px',
  },
  textFieldSearch: {
    width: '100%',
    fontSize: '20px',
    border: '1px solid #979797',
    borderRadius: '4px 4px 0 0',
    background: '#fff',
    '&:before': {
      border: 'none',
    },
    padding: '6px',
  },
  carouseltitlesectionwrapper: {
    width: '100%',
    backgroundColor: '#F8F1F2',
    textAlign: 'left',
    [theme.breakpoints.up('xs')]: {
      bottom: '5%',
      position: 'static',
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '15%',
      position: 'absolute',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '7%',
      position: 'absolute',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: '20%',
      position: 'absolute',
    },
  },
  carouseltitlesection: {
    backgroundColor: '#F8F1F2',
    color: '#000',
    textAlign: 'left',
    margin: '0 auto',
    maxWidth: '1600px',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '56.25%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  nomarginSection: {
    // margin: '0 auto !important',
    padding: theme.spacing(3),
    paddingBottom: '2em',
    maxWidth: '1600px',
    [theme.breakpoints.down('sm')]: {
      padding:'2em 0 0 0',
    }
  },
  noPaddingsection: {
    paddingBottom: 0,
  },
  nomarginSectionFull: {
    margin: '0 auto !important',
    padding: theme.spacing(3),
    paddingBottom: '2em',
    backgroundColor: 'transparent',
  },
  bluecontainer: {
    backgroundColor: '#ffffff',
  },
  nomarginSectionCarousel: {
    margin: '0 auto !important',
    paddingTop: '5em',
    backgroundColor: '#F8F1F2',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
      marginTop: '0 !important',
    },

    [theme.breakpoints.up('md')]: {
      marginTop: '-80px  !important',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-80px  !important',
    },
  },
  fullwidth: {
    width: '100%',
    marginBottom: 0,
  },
  fullwidthsearch: {
    width: '90%',
    display: 'inline-block',
    fontSize: '18px',
    paddingLeft: '8em',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      paddingLeft: '1em',
    },
  },
  searchbutton: {
    display: 'inline-block',
    backgroundColor: '#000 !important',
    background: '#000',
    color: '#fff',
    marginLeft: '1em',
    borderRadius: 0,
    marginTop: '18px',
    padding: '7px 7px',
  },
  control: {
    padding: theme.spacing(2),
  },
  sectiontitle: {
    margin: '0.4em',
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      float: 'none',
    },
  },
  carouselitem: {
    display:'none',
    [theme.breakpoints.up('md')]: {
      display:'block',
    },    
    [theme.breakpoints.up('lg')]: {},
  },
  half: {
    clear: 'both',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.4em',
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '50%',
      paddingLeft: '0.4em',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },

  promoText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em',
      marginTop: 0,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em',
      marginTop: 0,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.7em',
      marginTop: 0,
    },
  },
  alignleft: {
    textAlign: 'right',
  },
  promoicon: {
    fontSize: '7em',
    marginTop: '10px',
  },
  nomarginbottom: {
    marginBottom: 0,
  },
  searchContainer: {
    // backgroundImage: `url(${SearchBG})`,
    backgroundColor:'transparent',
    [theme.breakpoints.down('sm')]: {
    padding: '0 0.5em 0 0.5em',
    margin: '0em',
    height: '340px',
  }, 
  [theme.breakpoints.up('md')]: {
    padding: '0em',
    margin: '1em',
  },
  },
  carouseltitle: {
    padding: '0 0em 0 3em',
    lineHeight: '134.6%',

    [theme.breakpoints.down('sm')]: {
      padding: '0 0em 0 1em',

    },
    [theme.breakpoints.up('md')]: {

    },
    [theme.breakpoints.up('lg')]: {
    },
  },
  carouselsubtitle: {
    padding: '0',
    margin: '0 0em 4em 8.3em',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0em 4em 1.5em',
    },

    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.up('lg')]: {
    },
  },
  griditem: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '33%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
  },
  promoSection: {
    backgroundColor: '#F2C0C1',
  },
});

export class HomePage extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load evenemang
   */

  UNSAFE_componentWillMount() {
    this.tempimageheader = RandomImageHome();
    this.props.onSubmitForm();
    this.columns = 4;
    this.match = false;
    this.checkWidth = () => {
      const match = window.matchMedia(`(min-width: 2500px)`);
      if (match.matches) {
        this.match = true;
      } else {
        this.match = false;
      }
    };
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
  }

  render() {
    const {
      profiles,
      news,
      classes,
      events,
      userid,
      user,
      favoriter,
      masonryviewevents,
      listviewevents,
      masonryviewnews,
      listviewnews,
      searchfieldvalueevenemang,
      masonryviewprofiles,
      listviewprofiles,
      width,
      helptexts,
    } = this.props;
    let { columns } = this;
    if (width == 'xl') {
      columns = 5;
    }
    if (this.match) {
      columns = 6;
    }
    const home_info = '';

    return (
      <article>
        <Helmet>
          <title>Kulturforum - Svenskfinland</title>
          <meta
            name="Kulturforum - Svenskfinland"
            content="Kulturforum - Svenskfinland"
          />
        </Helmet>
        <div style={{ backgroundColor: '#FFF' }}>
        <Section className={classes.nomarginSectionCarousel}>
            <Grid container spacing={0} >
              <Grid item md={6} xs={12} style={{backgroundImage: `url(${SearchBG})`}}>
              <div className={classes.searchContainer}> 
                  <div>
                      <h1 className={classes.carouseltitle}>
                        Evenemang och kontakter i Svenskfinland
                      </h1>
                      <p className={classes.carouselsubtitle}>
                        <strong>
                        Vi ger synlighet och skapar kontakter på det finlandssvenska kulturfältet. 
                        </strong><br/>
                        Gör din egen profil helt kostnadsfritt och sprid dina evenemang och nyheter.
                      </p>
                    <TextField
                      id="search"
                      onChange={this.props.handleChangeAutoCompleteEvenemang}
                      // label="Titel"
                      className={classes.fullwidthsearch}
                      value={searchfieldvalueevenemang}
                      placeholder="Sök evenemang"
                      onKeyPress={this.props.searchKeyPressed}
                      margin="normal"
                      InputProps={{
                        className: classes.textFieldSearch,
                        disableUnderline: true,
                      }}
                      // value={titlevalue}
                    />
                    <Button
                      className={classes.searchbutton}
                      onClick={this.props.searchButtonPressed.bind(
                        this,
                        searchfieldvalueevenemang,
                      )}
                      color="primary"
                    >
                      SÖK
                    </Button>
                  </div>
                </div>
              </Grid> 
            <Grid item md={6} xs={12}>
            <Carousel
              infiniteLoop
              interval={6000}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
            >
              <div className={classes.carouselitem}>
                <Hidden xsDown>
                  {' '}
                  <img src={this.tempimageheader} />{' '}
                </Hidden>

                
              </div>
            </Carousel>
            </Grid> 
          </Grid>
          </Section>

          <div className={classes.bluecontainer}>
            <Section className={`${classes.nomarginSection}`}>
              <h2 className={classes.sectiontitle}>Evenemang denna vecka</h2>
              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonryEvents();
                  }}
                >
                  {' '}
                  <ViewModule />{' '}
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewListEvents();
                  }}
                >
                  {' '}
                  <ViewList />{' '}
                </IconButton>
              </div>
            </Section>

            <Section
              className={`${classes.nomarginSection} ${
                classes.noPaddingsection
              }`}
            >
              <EvenemangList
                limit={columns}
                home={false}
                loading={false}
                error={false}
                evenemang={events}
                masonryview={masonryviewevents}
                listview={listviewevents}
                user={user}
                userid={userid}
                favoriter={favoriter}
              />
              </Section>
              <Section className={classes.aligncenter}>
              <Button href="/evenemang" 
              variant="contained"
              disableElevation
              style={{color:'#fff',backgroundColor:'#000',margin:'1em'}}>
                + SE MER
              </Button>
            </Section>
          </div>
          <div>
            <Section className={classes.nomarginSection}>
            
              <Grid container spacing={2} direction="row"
              sx={{
                alignItems: "flex-end",
                justifyContent: "center"}}>
                <Grid item xs={12} md={3} className={classes.aligncenter}>
                <img src={KulturAktor} alt="KulturAktor" />

                <p>
                    <strong>Kulturaktörer & informatörer</strong>
                    <br />
                    Registrera dig och få synlighet för din verksamhet.
                    Fyll i på ett ställe och visas på flera.
                  </p>
                </Grid>
                <Grid item xs={12} md={3} className={classes.aligncenter}>
                <img src={Private} alt="Private" />

                <p>
                    <strong>Privat användare</strong>
                    <br />
                    Registrera dig och samla evenemang och profiler. Prenumerera på nyhetsbrev.                  </p>
                </Grid>
                <Grid item xs={12} md={3} className={classes.aligncenter}>
                <img src={News} alt="News" />

                <p>
                    <strong>Nyheter om stipendier och bidrag?</strong>
                    <br />
                    Söker du medel för dina aktiviteter? På databasen <a href="https://fyrk.fi">Fyrk.fi </a>
                    listar vi aktuella finansieringsformer.
                  </p>
                </Grid>
                <Grid item xs={12} md={3} className={classes.aligncenter}>
                <img src={Magazines} alt="Magazines" />

                <p>
                    <strong>
                      Vill du informera om ditt evenemang i media?
                    </strong>
                    <br />
                    För bättre synlighet av dina evenemang levererar Kulturforum.fi
                    information till mediahus.
                  </p>
                </Grid>
              </Grid>
            </Section>
          </div>
          <div className={classes.bluecontainer}>
            <Section className={classes.nomarginSection}>
              <h2 className={classes.sectiontitle}>
                Nyheter från Svenskfinland
              </h2>
              <p className={classes.half}>
                Här lägger du upp aktualiteter, information om projekt och
                händelser. Tipsa om lediga jobb och praktikplatser. Alla,
                registrerade eller inte kan prenumerera på våra nyhetsbrev
                utgående från intresse, aktör eller region.
              </p>

              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonryNews();
                  }}
                >
                  {' '}
                  <ViewModule />{' '}
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewListNews();
                  }}
                >
                  {' '}
                  <ViewList />{' '}
                </IconButton>
              </div>
            </Section>

            <Section className={classes.nomarginSection}>
              <NyheterList
                limit={columns}
                home={false}
                loading={false}
                error={false}
                nyheter={news}
                masonryview={masonryviewnews}
                listview={listviewnews}
                user={user}
                userid={userid}
                favoriter={favoriter}
              />
            </Section>
            <Section className={classes.aligncenter}>
              <Button href="/nyheter" 
              variant="contained"
              disableElevation
              style={{color:'#fff',backgroundColor:'#000',margin:'1em'}}>
                + SE MER
              </Button>
            </Section>
          </div>

          <div style={{ backgroundColor: '#FFF' }}>
            <Section
              className={`${classes.nomarginSection} ${classes.promoSection}`}
            >
              <Grid container spacing={10}>
                <Grid item xs={1} sm={2} />
                <Grid item xs={1} sm={2}>
                  <p className={classes.alignright}>
                    <Favorite fontSize='large' sx={{color:'#FF3237',fontSize:60}}className={classes.promoicon} />
                  </p>
                </Grid>
                <Grid item xs={8} sm={6}>
                  <h2 className={classes.nomarginbottom}>
                    Hjärtat på Kulturforum
                  </h2>
                  <p>
                    Samla och koppla samman profiler, händelser och nyheter som
                    intresserar dig med vår gillafunktion.
                  </p>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Section>
          </div>
          <div className={classes.bluecontainer}>
            <Section className={classes.nomarginSection}>
              <h2 className={classes.sectiontitle}>Aktuella profiler</h2>
              <div className={classes.alignright}>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewMasonryProfiles();
                  }}
                >
                  {' '}
                  <ViewModule />{' '}
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.props.onChangeViewListProfiles();
                  }}
                >
                  {' '}
                  <ViewList />{' '}
                </IconButton>
              </div>
            </Section>

            <Section className={classes.nomarginSection}>
              <KulturList
                limit={columns}
                home={false}
                loading={false}
                error={false}
                kultur={profiles}
                masonryview={masonryviewprofiles}
                listview={listviewprofiles}
                user={user}
                userid={userid}
                favoriter={favoriter}
              />
            </Section>
            <Section className={classes.aligncenter}>
              <Button href="/profiler" 
              variant="contained"
              disableElevation
              style={{color:'#fff',backgroundColor:'#000',margin:'1em'}}>
                + SE MER
              </Button>
            </Section>
          </div>
        </div>
      </article>
    );
  }
}

HomePage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  evenemang: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onSubmitForm: PropTypes.func,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    onSubmitForm: evt => {
      dispatch(loadData());
    },
    searchKeyPressed: (event, value) => {
      if (event.key === 'Enter') {
        dispatch(handleSearchEvenemang(event.target.value));
        dispatch(loadEvenemang());
        dispatch(push(`/evenemang`));
      }
    },
    searchButtonPressed: (evt, value) => {
      dispatch(handleSearchEvenemang(value));
      dispatch(loadEvenemang());
      dispatch(push(`/evenemang`));
    },
    onChangeViewListEvents: evt => dispatch(toListViewEvents()),
    onChangeViewMasonryEvents: evt => dispatch(toMasonryViewEvents()),
    onChangeViewListNews: evt => dispatch(toListViewNews()),
    onChangeViewMasonryNews: evt => dispatch(toMasonryViewNews()),
    onChangeViewListProfiles: evt => dispatch(toListViewProfiles()),
    onChangeViewMasonryProfiles: evt => dispatch(toMasonryViewProfiles()),
    handleChangeAutoCompleteEvenemang: (event, value) => {
      dispatch(handleSearchFieldValueEvenemang(event.target.value));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  profiles: getHomeProfiles(),
  news: getHomeNews(),
  events: getHomeEvents(),
  listviewevents: makeSelectListViewEvents(),
  masonryviewevents: makeSelectMasonryViewEvents(),
  listviewnews: makeSelectListViewNews(),
  masonryviewnews: makeSelectMasonryViewNews(),
  listviewprofiles: makeSelectListViewProfiles(),
  masonryviewprofiles: makeSelectMasonryViewProfiles(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  favoriter: getFavoriter(),
  helptexts: getHelp(),
  searchfieldvalueevenemang: makeSelectSearchFieldValueEvenemang(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'home', reducer });
const withSaga = injectSaga({ key: 'home', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withWidth(),
  withStyles(styles),
)(HomePage);
